import { useTranslation } from 'next-i18next';
import { Box, Button, Typography } from 'ui';

import { NavigatorStep, useCreateMeteringPoint } from '~/meteringPoint';
import { isCompletedAddress, isCompletedMeteringPoint } from '~/services/firestore';
import { DataSection, StepTitle } from '~/ui';

const Value = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" fontWeight={400}>
    {children}
  </Typography>
);

export const ConfirmationStep = () => {
  const { t } = useTranslation('createMeteringPointWizard');
  const { getMeteringPoint, setCurrentStep, currentMeteringPointIndex, goToNextStep, setRedirectTo } =
    useCreateMeteringPoint();

  const meteringPoint = getMeteringPoint(currentMeteringPointIndex);
  const { name, meteringPointId, address, previousUtility } = meteringPoint;

  const editStep = (step: NavigatorStep) => {
    setCurrentStep(step);
    setRedirectTo('confirmation');
  };

  return (
    <Box display="flex" flex={1} flexDirection="column" justifyContent="space-between">
      <Box>
        <Box mb={2} ml={1}>
          <StepTitle>{t('steps.confirmation.title')}</StepTitle>
        </Box>

        <Box display="flex" flexDirection="column" gap={3}>
          <DataSection
            label={t('steps.confirmation.name')}
            Value={
              <>
                <Value>{name}</Value>
                {name ? null : <Value>{t('steps.confirmation.missingData')}</Value>}
              </>
            }
            onClick={() => editStep('name')}
            // @ts-expect-error - suppress the error for the translation function
            error={!name ? t('steps.confirmation.error') : ''}
            data-testid="name"
          />

          <DataSection
            label={t('steps.confirmation.address')}
            Value={
              <Box>
                {address?.region && <Value>{address?.region},</Value>}
                <Value>
                  {address?.postCode && `${address.postCode},`} {address?.settlement && `${address.settlement},`}
                </Value>
                {address?.area && <Value>{address?.area},</Value>}
                <Value>
                  {address?.addressLine1} {address?.addressLine2}
                </Value>

                {address?.region ||
                address?.postCode ||
                address?.settlement ||
                address?.addressLine1 ||
                address?.addressLine2 ? null : (
                  <Value>{t('steps.confirmation.missingData')}</Value>
                )}
              </Box>
            }
            onClick={() => editStep('address')}
            // @ts-expect-error - suppress the error for the translation function
            error={!isCompletedAddress(address) ? t('steps.confirmation.error') : ''}
            data-testid="address"
          />

          <DataSection
            label={t('steps.confirmation.meteringPointId')}
            Value={<Value>{meteringPointId || t('steps.confirmation.noData')}</Value>}
            onClick={() => editStep('meteringPointId')}
            // @ts-expect-error - suppress the error for the translation function
            error={!meteringPointId ? t('steps.confirmation.error') : ''}
            data-testid="metering-point-id"
          />

          {previousUtility && (
            <DataSection
              label={t('steps.confirmation.previousUtility')}
              Value={<Value>{previousUtility || t('steps.confirmation.noData')}</Value>}
              onClick={() => editStep('meteringPointId')}
              // @ts-expect-error - suppress the error for the translation function
              error={!previousUtility ? t('steps.confirmation.error') : ''}
            />
          )}
        </Box>
      </Box>

      <Button
        size="lg"
        fullWidth
        onClick={goToNextStep}
        disabled={!isCompletedMeteringPoint(meteringPoint)}
        data-testid="save-metering-point-button"
        color="gradient"
      >
        {t('save')}
      </Button>
    </Box>
  );
};
