'use client';

import { DocumentData } from 'firebase/firestore';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import { Address } from '~/data';
import { MeteringPoint } from '~/services/firestore';
import { Steps, useStepNavigator } from '~/utils';

import { getNavigationSteps, NAVIGATOR_STEPS } from './steps';
import { DEFAULT_METERING_POINT_STATE, DEFAULT_STATE, useStateManager, UseStateManagerReturn } from './useStateManager';

type MeteringPointState = UseStateManagerReturn['state'];

type StateManagerActions = Omit<UseStateManagerReturn, 'state'>;

type StepNavigatorActions = Omit<ReturnType<typeof useStepNavigator>, 'getRedirectTo' | 'getHistory'>;

type CreateMeteringPointContext = StateManagerActions &
  StepNavigatorActions & {
    getAccountAddress: () => Address;
    currentMeteringPointIndex: MeteringPointState['currentMeteringPointIndex'];
    meteringPointsData: MeteringPointState['meteringPointsData'];
    getMeteringPoint: (meteringPointIndex: number) => MeteringPoint;
    getAllMeteringPoints: () => MeteringPoint[];
    getCurrentMeteringPointIndex: () => number;
    onCompletion: () => Promise<void>;
  };

const CreateMeteringPointContext = createContext<CreateMeteringPointContext>({
  addMeteringPoint: () => {},
  currentMeteringPointIndex: 0,
  getAccountAddress: () => ({
    addressLine1: '',
    addressLine2: '',
    area: '',
    municipality: '',
    postCode: '',
    region: '',
    settlement: '',
  }),
  getAllMeteringPoints: () => [],
  getCurrentMeteringPointIndex: () => 0,
  getCurrentStep: () => NAVIGATOR_STEPS[0],
  getMeteringPoint: () => DEFAULT_METERING_POINT_STATE,
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  meteringPointsData: DEFAULT_STATE.meteringPointsData,
  navigateWithRedirect: () => {},
  onCompletion: () => Promise.resolve(),
  removeMeteringPoint: () => {},
  setAccountId: () => {},
  setAddress: () => {},
  setCurrentMeteringPointIndex: () => {},
  setCurrentStep: () => {},
  setMeteringPointId: () => {},
  setMeteringPointsData: () => {},
  setName: () => {},
  setPreviousUtility: () => {},
  setRedirectTo: () => {},
});

interface CreateMeteringPointProviderProps {
  children: React.ReactNode;
  onCompletion: () => Promise<void>;
  onUpdate: (data: MeteringPointState['meteringPointsData']) => void;
  skipSteps?: Steps;
  onboardingData?: DocumentData | null;
}

export const CreateMeteringPointProvider = ({
  children,
  onCompletion,
  onUpdate,
  skipSteps,
  onboardingData,
}: CreateMeteringPointProviderProps) => {
  const navigatorSteps = getNavigationSteps(skipSteps);
  const onboardingMeteringPoints = onboardingData?.meteringPointsData;
  const {
    addMeteringPoint,
    removeMeteringPoint,
    setAccountId,
    setAddress,
    setCurrentMeteringPointIndex,
    setMeteringPointId,
    setMeteringPointsData,
    setPreviousUtility,
    setName,
    state,
  } = useStateManager({ onboardingData });

  const { getCurrentStep, goToNextStep, goToPreviousStep, setRedirectTo, setCurrentStep, navigateWithRedirect } =
    useStepNavigator({
      initialStep: onboardingMeteringPoints?.length ? NAVIGATOR_STEPS[5] : navigatorSteps[0],
      steps: navigatorSteps,
    });

  useEffect(() => {
    onUpdate(state.meteringPointsData);
  }, [onUpdate, state.meteringPointsData]);

  const getMeteringPoint = useCallback(
    (meteringPointIndex: number): MeteringPoint => {
      if (!state.meteringPointsData[meteringPointIndex]) {
        return DEFAULT_METERING_POINT_STATE;
      }

      return state.meteringPointsData[meteringPointIndex];
    },
    [state.meteringPointsData]
  );

  const getAllMeteringPoints = useCallback((): MeteringPoint[] => {
    return state.meteringPointsData;
  }, [state.meteringPointsData]);

  const getCurrentMeteringPointIndex = useCallback((): number => {
    return state.currentMeteringPointIndex;
  }, [state.currentMeteringPointIndex]);

  const getAccountAddress = useCallback((): Address => {
    return onboardingData?.customerData.address;
  }, [onboardingData?.customerData.address]);

  const context = useMemo(
    () => ({
      addMeteringPoint,
      getAccountAddress,
      getAllMeteringPoints,
      getCurrentMeteringPointIndex,
      getCurrentStep,
      getMeteringPoint,
      goToNextStep,
      goToPreviousStep,
      navigateWithRedirect,
      onCompletion,
      removeMeteringPoint,
      setAccountId,
      setAddress,
      setCurrentMeteringPointIndex,
      setCurrentStep,
      setMeteringPointId,
      setMeteringPointsData,
      setName,
      setPreviousUtility,
      setRedirectTo,
      ...state,
    }),
    [
      addMeteringPoint,
      getAccountAddress,
      getAllMeteringPoints,
      getCurrentMeteringPointIndex,
      getCurrentStep,
      getMeteringPoint,
      goToNextStep,
      goToPreviousStep,
      navigateWithRedirect,
      onCompletion,
      removeMeteringPoint,
      setAccountId,
      setAddress,
      setCurrentMeteringPointIndex,
      setCurrentStep,
      setMeteringPointId,
      setMeteringPointsData,
      setName,
      setPreviousUtility,
      setRedirectTo,
      state,
    ]
  );

  return <CreateMeteringPointContext.Provider value={context}>{children}</CreateMeteringPointContext.Provider>;
};

export const useCreateMeteringPoint = () => {
  const context = useContext(CreateMeteringPointContext);

  if (!context) {
    throw new Error('useCreateMeteringPoint must be used within a CreateMeteringPointProvider');
  }

  return context;
};
