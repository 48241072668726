'use client';

import { styled } from 'ui';

export const GradientDivider = styled('div')(({ theme }) => ({
  background: `transparent ${theme.palette.gradient.rainbow} 0% 0% no-repeat padding-box`,
  borderRadius: 10,
  height: 7,
  margin: `${theme.spacing(4)} 0`,
  width: '100%',
}));
