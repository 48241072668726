'use client';

import { DocumentData } from 'firebase/firestore';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import { Steps } from '~/utils';

import { BEFORE_PREVIOUS_CALLBACKS } from './navigationCallbacks';
import { StepNavigatorActions } from './types';
import { useBusinessStepNavigator } from './useBusinessStepNavigator';
import { useResidentStepNavigator } from './useResidentStepNavigator';
import { DEFAULT_STATE, useStateManager, UseStateManagerReturn } from './useStateManager';

type CustomerState = UseStateManagerReturn['state'];

type StateManagerActions = Omit<UseStateManagerReturn, 'state'>;

type CreateCustomerContext = StateManagerActions &
  StepNavigatorActions & {
    customerData: CustomerState['customerData'];
    getCustomerData: () => CustomerState['customerData'];
    getCustomerContact: () => CustomerState['customerData']['contact'];
    getCustomerBillingContact: () => CustomerState['customerData']['billingContact'];
    getResidentialCustomerContact: () => CustomerState['customerData']['contact'];
    getRepresentatives: () => CustomerState['customerData']['representatives'];
    isCompany: () => boolean;
    onCompletion: () => void;
    businessStepNavigator: ReturnType<typeof useBusinessStepNavigator> | null;
    residentStepNavigator: ReturnType<typeof useResidentStepNavigator> | null;
  };

const CreateCustomerContext = createContext<CreateCustomerContext>({
  businessStepNavigator: null,
  customerData: DEFAULT_STATE.customerData,
  getCurrentStep: () => '',
  getCustomerBillingContact: () => DEFAULT_STATE.customerData.billingContact,
  getCustomerContact: () => DEFAULT_STATE.customerData.contact,
  getCustomerData: () => DEFAULT_STATE.customerData,
  getHistory: () => [],
  getRepresentatives: () => [],
  getResidentialCustomerContact: () => DEFAULT_STATE.customerData.contact,
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  isCompany: () => false,
  navigateWithRedirect: () => {},
  navigatorSteps: [],
  onCompletion: () => {},
  removeRepresentative: () => {},
  residentStepNavigator: null,
  setAccountManager: () => {},
  setAddress: () => {},
  setCurrentRepresentativeIndex: () => {},
  setCurrentStep: () => {},
  setCustomerBillingContact: () => {},
  setCustomerContact: () => {},
  setCustomerData: () => {},
  setCustomerType: () => {},
  setEIK: () => {},
  setLegalEntityName: () => {},
  setName: () => {},
  setPersonalId: () => {},
  setPlan: () => {},
  setRedirectTo: () => {},
  setRepresentatives: () => {},
});

interface CreateCustomerProviderProps {
  children: React.ReactNode;
  onCompletion: () => void;
  onUpdate: (data: CustomerState['customerData']) => void;
  onboardingData?: DocumentData | null;
  skipSteps?: Steps;
}

export const CreateCustomerProvider = ({
  children,
  onCompletion,
  onUpdate,
  onboardingData,
  skipSteps,
}: CreateCustomerProviderProps) => {
  const stateManager = useStateManager({ onboardingData });

  const {
    setAddress,
    setCurrentRepresentativeIndex,
    setCustomerData,
    setCustomerType,
    setCustomerContact,
    setCustomerBillingContact,
    setEIK,
    setLegalEntityName,
    setName,
    setAccountManager,
    setPersonalId,
    setRepresentatives,
    removeRepresentative,
    setPlan,
    state,
  } = stateManager;

  useEffect(() => {
    onUpdate(state.customerData);
  }, [onUpdate, state.customerData]);

  const getRepresentatives = useCallback((): CustomerState['customerData']['representatives'] => {
    return state.customerData.representatives;
  }, [state.customerData.representatives]);

  const getCustomerData = useCallback((): CustomerState['customerData'] => {
    return state.customerData;
  }, [state.customerData]);

  const getCustomerContact = useCallback((): CustomerState['customerData']['contact'] => {
    return state.customerData.contact;
  }, [state.customerData.contact]);

  const getCustomerBillingContact = useCallback((): CustomerState['customerData']['billingContact'] => {
    return state.customerData.billingContact;
  }, [state.customerData.billingContact]);

  const isCompany = useCallback((): boolean => {
    return state.customerData.customerType === 'company';
  }, [state.customerData.customerType]);

  const getResidentialCustomerContact = useCallback((): CustomerState['customerData']['contact'] => {
    return {
      email: onboardingData?.accountData.email,
      firstName: state.customerData.firstName,
      lastName: state.customerData.lastName,
      phone: onboardingData?.accountData.phoneNumber,
    };
  }, [
    onboardingData?.accountData?.email,
    onboardingData?.accountData?.phoneNumber,
    state.customerData.firstName,
    state.customerData.lastName,
  ]);

  const businessStepNavigator = useBusinessStepNavigator({ onboardingData, skipSteps });
  const residentStepNavigator = useResidentStepNavigator({ onboardingData, skipSteps });

  const {
    getCurrentStep,
    getHistory,
    goToNextStep,
    setCurrentStep,
    setRedirectTo,
    navigateWithRedirect,
    navigatorSteps,
    ...navigator
  } = isCompany() ? businessStepNavigator : residentStepNavigator;

  const goToPreviousStep = useCallback(() => {
    const currentStep = getCurrentStep();
    const beforePreviousCallback = typeof currentStep === 'string' ? BEFORE_PREVIOUS_CALLBACKS[currentStep] : null;

    if (beforePreviousCallback) {
      beforePreviousCallback(stateManager);
    }

    navigator.goToPreviousStep();
  }, [navigator, getCurrentStep, stateManager]);

  const context = useMemo(
    () => ({
      businessStepNavigator,
      getCurrentStep,
      getCustomerBillingContact,
      getCustomerContact,
      getCustomerData,
      getHistory,
      getRepresentatives,
      getResidentialCustomerContact,
      goToNextStep,
      goToPreviousStep,
      isCompany,
      navigateWithRedirect,
      navigatorSteps,
      onCompletion,
      removeRepresentative,
      residentStepNavigator,
      setAccountManager,
      setAddress,
      setCurrentRepresentativeIndex,
      setCurrentStep,
      setCustomerBillingContact,
      setCustomerContact,
      setCustomerData,
      setCustomerType,
      setEIK,
      setLegalEntityName,
      setName,
      setPersonalId,
      setPlan,
      setRedirectTo,
      setRepresentatives,
      ...state,
    }),
    [
      businessStepNavigator,
      getCurrentStep,
      getCustomerBillingContact,
      getCustomerContact,
      getCustomerData,
      getHistory,
      getRepresentatives,
      getResidentialCustomerContact,
      goToNextStep,
      goToPreviousStep,
      isCompany,
      navigateWithRedirect,
      navigatorSteps,
      onCompletion,
      removeRepresentative,
      residentStepNavigator,
      setAccountManager,
      setAddress,
      setCurrentRepresentativeIndex,
      setCurrentStep,
      setCustomerBillingContact,
      setCustomerContact,
      setCustomerData,
      setCustomerType,
      setEIK,
      setLegalEntityName,
      setName,
      setPersonalId,
      setPlan,
      setRedirectTo,
      setRepresentatives,
      state,
    ]
  );

  return <CreateCustomerContext.Provider value={context}>{children}</CreateCustomerContext.Provider>;
};

export const useCreateCustomer = () => {
  const context = useContext(CreateCustomerContext);

  if (!context) {
    throw new Error('useCreateCustomer must be used within a CreateCustomerProvider');
  }

  return context;
};
