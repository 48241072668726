import { Reducer, useReducer } from 'react';
import { Phone } from 'types';
import { countriesData } from 'ui';

import { DEFAULT_COUNTRY_BG } from '../../config/constants';
import { Action, SET_ACCOUNT_DATA, SET_EMAIL, SET_IS_SUBSCRIBED_FOR_MARKETING, SET_PHONE_NUMBER } from './actions';
import { stateReducer } from './stateReducer';
import { CreateAccountState } from './types';

export const DEFAULT_STATE: CreateAccountState = {
  accountData: {
    email: '',
    isSubscribedForMarketing: false,
    phoneNumber: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
  },
};

export type UseStateManagerReturn = {
  setEmail: (email: string) => void;
  setPhoneNumber: (phoneNumber: Phone) => void;
  setAccountData: (accountData: CreateAccountState['accountData']) => void;
  setIsSubscribedForMarketing: (isSubscribedForMarketing: boolean) => void;
  state: CreateAccountState;
};

export const useStateManager = (): UseStateManagerReturn => {
  const [state, dispatch] = useReducer<Reducer<CreateAccountState, Action>>(stateReducer, DEFAULT_STATE);

  const setEmail = (email: string): void => {
    dispatch({ payload: { email }, type: SET_EMAIL.type });
  };

  const setPhoneNumber = (phoneNumber: Phone): void => {
    dispatch({ payload: { phoneNumber }, type: SET_PHONE_NUMBER.type });
  };

  const setAccountData = (accountData: CreateAccountState['accountData']): void => {
    dispatch({ payload: { ...accountData }, type: SET_ACCOUNT_DATA.type });
  };

  const setIsSubscribedForMarketing = (isSubscribedForMarketing: boolean): void => {
    dispatch({ payload: { isSubscribedForMarketing }, type: SET_IS_SUBSCRIBED_FOR_MARKETING.type });
  };

  return { setAccountData, setEmail, setIsSubscribedForMarketing, setPhoneNumber, state };
};
