'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { AppLoader } from 'ui';

import { useAuthUser } from '../hooks/useAuthUser';

export const withAuthPage = (Page: () => JSX.Element) => {
  return (props = {}) => {
    const { isAuthenticated, isLoading } = useAuthUser();
    const { replace } = useRouter();

    useEffect(() => {
      if (!isAuthenticated() && !isLoading) {
        replace('/login');
      }
    }, [isAuthenticated, isLoading, replace]);

    if (isLoading) {
      return <AppLoader />;
    }

    if (isAuthenticated()) {
      return <Page {...props} />;
    }

    return null;
  };
};
