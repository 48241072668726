'use client';

import React from 'react';

import { withImpersonationPageAccess } from '../utils/withImpersonationPageAccess';

const ImpersonationWrapper = ({ children }: { children: JSX.Element }) => {
  const ImpersonationComponent = withImpersonationPageAccess(() => children);
  return <ImpersonationComponent />;
};

export default ImpersonationWrapper;
