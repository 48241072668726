import NextLink from 'next/link';
import { Box } from 'ui';

const transition = {
  '&:hover': { cursor: 'pointer', transform: 'scale(1.05)', transition: 'all .2s ease' },
  '&:not(:hover)': { transition: 'all .15s ease' },
  textDecoration: 'none',
};

interface Props {
  href: string;
  children: React.ReactNode;
  withTransition?: boolean;
  onClick?: () => void;
}

export const LinkContainer = ({ children, href, withTransition = true, onClick }: Props) => (
  <Box component={NextLink} href={href} sx={withTransition ? transition : {}} onClick={onClick}>
    {children}
  </Box>
);
