import { Phone } from 'types';
import { countriesData } from 'ui';

import { DEFAULT_COUNTRY_BG } from '../../config/constants';
import { CreateAccountState } from './types';

type SetEmailAction = {
  payload: {
    email: string;
  };
  type: 'SET_EMAIL';
};

export const SET_EMAIL: SetEmailAction = {
  payload: {
    email: '',
  },
  type: 'SET_EMAIL',
};

type SetPhoneNumberAction = {
  payload: {
    phoneNumber: Phone;
  };
  type: 'SET_PHONE_NUMBER';
};

export const SET_PHONE_NUMBER: SetPhoneNumberAction = {
  payload: {
    phoneNumber: { country: DEFAULT_COUNTRY_BG, countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode, value: '' },
  },
  type: 'SET_PHONE_NUMBER',
};

type SetIsSubscribedForMarketingAction = {
  payload: {
    isSubscribedForMarketing: boolean;
  };
  type: 'SET_IS_SUBSCRIBED_FOR_MARKETING';
};

export const SET_IS_SUBSCRIBED_FOR_MARKETING: SetIsSubscribedForMarketingAction = {
  payload: {
    isSubscribedForMarketing: false,
  },
  type: 'SET_IS_SUBSCRIBED_FOR_MARKETING',
};

type SetAccountDataAction = {
  payload: CreateAccountState['accountData'];
  type: 'SET_ACCOUNT_DATA';
};

export const SET_ACCOUNT_DATA: SetAccountDataAction = {
  payload: {
    email: '',
    isSubscribedForMarketing: false,
    phoneNumber: {
      country: DEFAULT_COUNTRY_BG,
      countryCode: countriesData[DEFAULT_COUNTRY_BG].countryCode,
      value: '',
    },
  },
  type: 'SET_ACCOUNT_DATA',
};

export type Action = SetEmailAction | SetPhoneNumberAction | SetAccountDataAction | SetIsSubscribedForMarketingAction;
