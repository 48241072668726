import { Reducer } from 'react';

import { Action, SET_ACCOUNT_DATA, SET_EMAIL, SET_IS_SUBSCRIBED_FOR_MARKETING, SET_PHONE_NUMBER } from './actions';
import { CreateAccountState } from './types';

export const stateReducer: Reducer<CreateAccountState, Action> = (prevState, action) => {
  switch (action.type) {
    case SET_EMAIL.type:
    case SET_PHONE_NUMBER.type:
    case SET_ACCOUNT_DATA.type:
    case SET_IS_SUBSCRIBED_FOR_MARKETING.type:
      return {
        ...prevState,
        accountData: {
          ...prevState.accountData,
          ...action.payload,
        },
      };
    default:
      return prevState;
  }
};
