import { FeatureApiResponse, FeatureFlagsProvider, getGrowthBookPayload } from 'feature-flag';
import { useTagManager } from 'firebase-client/analytics';
import logger, { initLogger } from 'logger';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ErrorInfo, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { addError, Errors, initRum, setGlobalContext } from 'rum';
import { AppLoader, Box, ErrorFallback, ThemeProvider } from 'ui';

import { AuthUserProvider } from '~/auth';
import QueryProvider from '~/data';
import { I18nProvider } from '~/i18n';

initRum({ service: 'blender' });
setGlobalContext({
  env: process.env,
});
initLogger({ service: 'blender' });

const onError = (error: Error, info: ErrorInfo) => {
  addError(Errors.UNHANDLED_ERROR, error, {
    info,
  });
};

const FallbackComponent = () => {
  const { t } = useTranslation('common');
  const errorTitle = t('errorPage.default.title');
  const errorDescription = t('errorPage.default.description');
  const supportEmail = t('supportEmail');

  return (
    <Box height="100vh">
      <ErrorFallback title={errorTitle} description={errorDescription} supportEmail={supportEmail} />
    </Box>
  );
};

interface Props {
  Component: React.ElementType;
  pageProps: Record<string, unknown>;
  payload?: FeatureApiResponse;
}

const BlenderApp = ({ Component, pageProps, payload }: Props) => {
  useTagManager({
    gtmId: process.env.NEXT_PUBLIC_BLENDER_GTM_CONFIG!,
  });

  useEffect(() => {
    logger.info('BlenderApp', { pageProps });
  }, [pageProps]);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      <QueryProvider>
        <I18nProvider>
          <ThemeProvider>
            <Suspense fallback={<AppLoader />}>
              <AuthUserProvider>
                <FeatureFlagsProvider payload={payload || {}}>
                  <Component {...pageProps} />
                </FeatureFlagsProvider>
              </AuthUserProvider>
            </Suspense>
          </ThemeProvider>
        </I18nProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
};

BlenderApp.getInitialProps = async () => {
  return { ...(await getGrowthBookPayload()) };
};

export default appWithTranslation(BlenderApp);
