'use client';

import { createContext, useCallback, useContext, useMemo } from 'react';
import { Errors } from 'rum';

import { createUser, loginUserWithPassword } from '~/auth';
import { useStepNavigator } from '~/utils';

import { NAVIGATOR_STEPS } from './steps';
import { DEFAULT_STATE, useStateManager, UseStateManagerReturn } from './useStateManager';

type AccountState = UseStateManagerReturn['state'];

type StateManagerActions = Omit<UseStateManagerReturn, 'state'>;

type StepNavigatorActions = Omit<
  ReturnType<typeof useStepNavigator>,
  'getRedirectTo' | 'setCurrentStep' | 'setRedirectTo' | 'navigateWithRedirect' | 'getHistory'
>;

type CreateAccountContext = StateManagerActions &
  StepNavigatorActions & {
    accountData: AccountState['accountData'];
    getAccountData: () => AccountState['accountData'];
    onCompletion: () => void;
    createAccount: (
      password: string,
      setIsLoading: (loading: boolean) => void,
      onSuccess: () => void,
      onError: (errorName: Errors, error: unknown) => void
    ) => Promise<void>;
  };

const CreateAccountContext = createContext<CreateAccountContext>({
  accountData: DEFAULT_STATE.accountData,
  createAccount: async () => {},
  getAccountData: () => DEFAULT_STATE.accountData,
  getCurrentStep: () => NAVIGATOR_STEPS[0],
  goToNextStep: () => {},
  goToPreviousStep: () => {},
  onCompletion: () => {},
  setAccountData: () => {},
  setEmail: () => {},
  setIsSubscribedForMarketing: () => {},
  setPhoneNumber: () => {},
});

interface CreateAccountProviderProps {
  children: React.ReactNode;
  onCompletion: () => void;
  onUpdate: (data: AccountState['accountData']) => void;
}

export const CreateAccountProvider = ({ children, onCompletion, onUpdate }: CreateAccountProviderProps) => {
  const { state, setEmail, setPhoneNumber, setAccountData, setIsSubscribedForMarketing } = useStateManager();

  const { getCurrentStep, goToNextStep, goToPreviousStep } = useStepNavigator({
    initialStep: NAVIGATOR_STEPS[0],
    steps: NAVIGATOR_STEPS,
  });

  const getAccountData = useCallback((): AccountState['accountData'] => {
    return state.accountData;
  }, [state.accountData]);

  const createAccount = useCallback(
    async (
      password: string,
      setIsLoading: (loading: boolean) => void,
      onSuccess: () => void,
      onError: (errorName: Errors, error: unknown) => void
    ) => {
      setIsLoading(true);
      const accountData = getAccountData();
      try {
        const credentials = await createUser({
          email: accountData.email,
          password,
        });

        if (!credentials.user) {
          onError(Errors.AUTH_SIGN_UP_FAILURE, "Authentication failed. User wasn't created.");
          return;
        }
        await loginUserWithPassword({ email: accountData.email, password });
        onUpdate(accountData);
        setIsLoading(false);
        onSuccess();
      } catch (error) {
        setIsLoading(false);
        onError(Errors.AUTH_SIGN_UP_LOGIN_FAILURE, error);
      }
    },
    [getAccountData, onUpdate]
  );

  const context = useMemo(() => {
    return {
      createAccount,
      getAccountData,
      getCurrentStep,
      goToNextStep,
      goToPreviousStep,
      onCompletion,
      setAccountData,
      setEmail,
      setIsSubscribedForMarketing,
      setPhoneNumber,
      ...state,
    };
  }, [
    createAccount,
    getAccountData,
    getCurrentStep,
    goToNextStep,
    goToPreviousStep,
    onCompletion,
    setAccountData,
    setEmail,
    setPhoneNumber,
    setIsSubscribedForMarketing,
    state,
  ]);

  return <CreateAccountContext.Provider value={context}>{children}</CreateAccountContext.Provider>;
};

export const useCreateAccount = () => {
  const context = useContext(CreateAccountContext);

  if (!context) {
    throw new Error('useCreateAccount must be used within a CreateAccountProvider');
  }

  return context;
};
