'use client';

import React from 'react';

import { withAuthPage } from '~/auth';

const AuthWrapper = ({ children }: { children: JSX.Element }) => {
  const AuthenticatedComponent = withAuthPage(() => children);
  return <AuthenticatedComponent />;
};

export default AuthWrapper;
