import { FieldValue } from 'firebase/firestore';
import { auth } from 'firebase-client';
import logger from 'logger';

import { createOnboardingRequest, getOnboardingRequest, updateOnboardingRequest } from './storageAccessor';
import { OnboardingRequest } from './types';

const getValidatedUser = () => {
  const user = auth.currentUser;

  if (!user || !user.uid) {
    throw new Error('User is not defined');
  }

  return user;
};

const fetchRequest = async () => {
  try {
    const user = getValidatedUser();
    const request = await getOnboardingRequest(user.uid);

    return request.data;
  } catch (error) {
    logger.error('Error while getting onboarding request', { error });
  }

  return null;
};

const createRequest = async (data: Omit<OnboardingRequest, 'userId'>) => {
  try {
    const user = getValidatedUser();
    await createOnboardingRequest({ completed: false, userId: user.uid, ...data });
  } catch (error) {
    logger.error('Error while creating onboarding request', { error });
  }
};

const updateRequest = async (fields: { [x: string]: FieldValue | Partial<unknown> | null }) => {
  try {
    const user = getValidatedUser();
    await updateOnboardingRequest(user.uid, fields);
  } catch (error) {
    logger.error('Error while updating onboarding request', { error });
  }
};

export { createRequest, fetchRequest, updateRequest };
