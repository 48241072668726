'use client';

import React from 'react';
import { Box, Button, Logo, Typography } from 'ui';
import { ArrowBack } from 'ui/icons';

interface Props {
  title: string;
  onBackClick?: () => void;
  showBackButton?: boolean;
}

export const MobilePageHeader = ({ title, onBackClick, showBackButton = true }: Props) => {
  return (
    <>
      <Box display="grid" gap={2} my={2} gridTemplateColumns={showBackButton ? '50px auto' : 'none'}>
        {showBackButton && (
          <Button variant="square" color="white" size="sm" onClick={onBackClick} data-testid="back-button">
            <ArrowBack />
          </Button>
        )}
        <Box mt={1}>
          <Logo />
        </Box>
      </Box>
      <Box>
        <Typography variant="h5" color="primary" fontWeight="bold">
          {title}
        </Typography>
      </Box>
    </>
  );
};
