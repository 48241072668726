import { useFeatureIsOn } from 'feature-flag';
import { useEffect, useState } from 'react';

import { CompletedOnboardingRequest, fetchRequest } from '~/services/firestore';

type UseIsContinueOnboardingEnabledReturn = {
  isEnabled: boolean;
  data: CompletedOnboardingRequest | null;
};

export const useIsContinueOnboardingEnabled = (): UseIsContinueOnboardingEnabledReturn => {
  const isOnboardingEnabled = useFeatureIsOn('onboarding');
  const [onboardingData, setOnboardingData] = useState<CompletedOnboardingRequest | null>(null);

  useEffect(() => {
    if (isOnboardingEnabled) {
      fetchRequest().then((data) => {
        setOnboardingData(data);
      });
    }
  }, [isOnboardingEnabled]);

  return {
    data: onboardingData,
    isEnabled:
      isOnboardingEnabled &&
      !onboardingData?.completed &&
      Boolean(onboardingData?.customerData?.planId) &&
      Object.keys(onboardingData || {}).length > 0,
  };
};
