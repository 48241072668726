import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import logger from 'logger';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Alert, Box, Button, Fab, Form, Modal, Typography, useMediaQuery, useTheme } from 'ui';
import { QuestionMarkOutlined } from 'ui/icons';
import { object, string } from 'yup';

import { MAX_MESSAGE_LENGTH } from '~/config/constants';

import { useSendMail } from '../../hooks/useSendMail';

interface FormValues {
  message: string;
}

const initialFormValues = {
  message: '',
};

const ContactForm = () => {
  const { t } = useTranslation();
  const { sendCustomerInquiry, isPending, isSuccess, isError, reset: resetSendMailStatus } = useSendMail();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);

    setTimeout(() => {
      resetSendMailStatus();
    }, 200);
  };

  const resolver = yupResolver(
    object({
      message: string()
        .test(
          'maxLength',
          // @ts-expect-error - suppress the error for the translation function -
          t('validation:maxMessageLength', { length: MAX_MESSAGE_LENGTH }),
          (val) => val !== undefined && val.length <= MAX_MESSAGE_LENGTH
        )
        // @ts-expect-error - suppress the error for the translation function
        .required(t('validation:required')),
    })
  );

  const { handleSubmit, watch, reset, ...rest } = useForm<FormValues>({
    defaultValues: initialFormValues,
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    try {
      await sendCustomerInquiry({ message: formValues.message });
      reset();
    } catch (err) {
      logger.error('Error sending customer inquiry', { err });
    }
  };

  return (
    <>
      <Fab
        onClick={handleOpen}
        variant="circular"
        size="medium"
        color="primary"
        sx={{ bottom: { lg: 40, xs: 130 }, position: 'fixed', right: { lg: 40, xs: 15 } }}
      >
        <QuestionMarkOutlined sx={{ color: 'common.white' }} />
      </Fab>
      <Modal
        open={showModal}
        handleClose={handleClose}
        title={
          <Typography variant={isMobile ? 'h5' : 'h4'} component="span" display="flex" justifyContent="center" mb={1}>
            {t('contactForm.title')}
          </Typography>
        }
        content={
          <FormProvider {...{ handleSubmit, watch, ...rest, reset }}>
            <Form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                margin="normal"
                multiline
                minRows={3}
                fullWidth
                label={t('contactForm.message')}
                name="message"
              />
              {isSuccess && <Alert severity="success">{t('contactForm.successMessage')}</Alert>}
              {isError && <Alert severity="error">{t('contactForm.errorMessage')}</Alert>}
            </Form>
          </FormProvider>
        }
        actions={
          <Box mb={2}>
            <Button type="submit" form="contactForm" loading={isPending}>
              {t('contactForm.submit')}
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ContactForm;
