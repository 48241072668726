'use client';

import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { BottomNavigation as UiBottomNavigation } from 'ui';

import { convertHrefToEventKey } from '~/utils';

import { NavigationOptions } from '../types';

interface Props {
  options: NavigationOptions[];
}

const BottomNavigation = ({ options }: Props) => {
  const pathname = usePathname();
  return (
    <UiBottomNavigation currentPath={pathname!}>
      {options.map(({ href, icon }, i) => {
        const eventKey = convertHrefToEventKey(href);
        return (
          <UiBottomNavigation.Item
            key={i}
            data-testid={`bottom-nav-${href.slice(1)}`}
            component={Link}
            href={href}
            value={href}
            onClick={() => trackTagManagerEvent(BlenderEvents[eventKey as keyof typeof BlenderEvents])}
            icon={icon}
          />
        );
      })}
    </UiBottomNavigation>
  );
};

export default BottomNavigation;
