import { useQuery } from '@tanstack/react-query';

import { fetchRequest } from '~/services/firestore';

export const useOnboardingData = ({ currentWizard }: { currentWizard: string }) => {
  const { data, isLoading, refetch } = useQuery({
    enabled: currentWizard !== 'account',
    queryFn: fetchRequest,
    queryKey: [`${currentWizard}`],
  });

  return { data, isLoading, refetch };
};
