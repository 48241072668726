import {
  Annex as AnnexBasic,
  Contract as ContractBasic,
  CustomerBasic,
  InvoiceBasic,
  LineItem,
  PointInvoiceAddendum,
} from '@toki-power/public-api';

import ResponseError from './api/ResponseError';

export type {
  Address,
  Page as ApiPage,
  Contact,
  GetContractFileV1200ResponseData as ContractFile,
  GetCustomerTypeByEmailv1200ResponseData as CustomerProfileType,
  ImpersonalizedCustomers as ImpersonalizedCustomer,
  InvoiceDetails,
  Message,
  MeteringPoint,
  Middleware,
  PointInvoiceAddendum,
  ResponseContext,
} from '@toki-power/public-api';

export type BaseHTTPResponse<T> = {
  data: T;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: ResponseError | null;
};

export type Customer = CustomerBasic;

export type CustomerType = 'person' | 'company' | null;

export type Invoice = InvoiceBasic;

export type Contract = ContractBasic;

export type Annex = AnnexBasic;

export const isInvoice = (invoice: Invoice | PointInvoiceAddendum): invoice is Invoice => {
  return Object.prototype.hasOwnProperty.call(invoice, 'pdfUrl');
};

export type InvoiceLineItem = LineItem;

export interface Page<T> {
  items: T[];
  total: number;
  page: number;
  pages: number;
  size: number;
}

export type PageParams = {
  size: number;
  page: number;
};

export interface GetAllOptions<T> {
  page?: PageParams;
  filter?: T;
}
