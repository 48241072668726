import { Annex, AnnexApi, Configuration } from '@toki-power/public-api';

import { Page, PageParams } from '../types';

export type AnnexReturnType = {
  getContractAnnexes: (contractNumber: string, page: PageParams) => Promise<Page<Annex>>;
};

export const annexApi = (config: () => Promise<Configuration>): AnnexReturnType => {
  const api = async () => new AnnexApi(await config());

  const getContractAnnexes = async (contractNumber: string, { page, size }: PageParams) =>
    (await api()).getAnnexesOfContractV1({ contractNumber, page, size });

  return {
    getContractAnnexes,
  };
};
