import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import { CardListContainer, MobileSwiper, useResolution } from 'ui';

import { MeteringPoint } from '~/data';
import { MeteringPointCard } from '~/meteringPoint';

interface Props {
  list: MeteringPoint[];
  total: number;
}

export const CustomerMeteringPoints = ({ list, total }: Props) => {
  const { isDesktop } = useResolution();

  const LIMIT = 5;

  const cardsList = list.slice(0, LIMIT).map((meteringPoint) => {
    return (
      <MeteringPointCard
        key={meteringPoint.meteringPointId}
        meteringPoint={meteringPoint}
        onClick={() => trackTagManagerEvent(BlenderEvents.customerMeteringPointDetails)}
      />
    );
  });

  return isDesktop ? (
    <CardListContainer elements={cardsList} maxElements={LIMIT} totalElements={total} seeMoreHref="/metering-points" />
  ) : (
    <MobileSwiper elements={cardsList} />
  );
};
