'use client';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { i18nConfig } from './config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

if (!i18n.isInitialized) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        // Path to load translation files
        loadPath: path.resolve('/locales/{{lng}}/{{ns}}.json'),
      },
      ...i18nConfig,
    });
}

export default i18n;
