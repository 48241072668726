import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Box, Button, Typography } from 'ui';

import { parseConsumerResponseError, ResponseError } from '~/data/api';
import { useCreateMeteringPoint } from '~/meteringPoint';
import { isCompletedMeteringPoint } from '~/services/firestore';
import { StepTitle } from '~/ui';

import { ErrorMessage } from './ErrorMessage';
import { LoadingMessage } from './LoadingMessage';
import { MeteringPointCard } from './MeteringPointCard';

type HasOnCompletionError = {
  error: boolean;
  errorMessage?: string;
};

export const CompletionStep = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasOnCompletionError, setHasOnCompletionError] = useState<HasOnCompletionError>({
    error: false,
    errorMessage: undefined,
  });
  const { t } = useTranslation('createMeteringPointWizard');
  const { onCompletion, setCurrentStep, getAllMeteringPoints, setCurrentMeteringPointIndex, removeMeteringPoint } =
    useCreateMeteringPoint();

  const meteringPoints = getAllMeteringPoints();

  const hasMeteringPointMissingData = !meteringPoints.every(isCompletedMeteringPoint);
  const isHidden = isLoading || hasOnCompletionError.error;

  const isDisabled = meteringPoints.length === 0 || hasMeteringPointMissingData;

  const goToCreateMeteringPoint = () => {
    setCurrentMeteringPointIndex(meteringPoints.length);
    setCurrentStep('name');
  };

  const editMeteringPoint = (index: number) => {
    setCurrentMeteringPointIndex(index);
    setCurrentStep('confirmation');
  };

  const onCompletionButtonPress = async () => {
    trackTagManagerEvent(OnboardingEvents.submitMeteringPoints);
    setIsLoading(true);

    try {
      await onCompletion();
    } catch (error) {
      let completionError;

      if (error instanceof ResponseError) {
        completionError = await parseConsumerResponseError(error);

        if (completionError) {
          completionError = t(`steps.completion.${completionError}`);
        }
      }

      setHasOnCompletionError({
        error: true,
        errorMessage: completionError,
      });
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }}>
        {!isLoading && !hasOnCompletionError.error && <StepTitle>{t('steps.completion.title')}</StepTitle>}
        {hasMeteringPointMissingData && (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="body1" color="error">
              {t('steps.confirmation.error')}
            </Typography>
          </Box>
        )}

        {isLoading && <LoadingMessage />}

        {!isLoading && !hasOnCompletionError.error && (
          <>
            {meteringPoints.map((meteringPoint, index) => (
              <Box mb={2} key={meteringPoint.meteringPointId}>
                <MeteringPointCard
                  meteringPoint={meteringPoint}
                  editMeteringPoint={() => editMeteringPoint(index)}
                  removeMeteringPoint={() => removeMeteringPoint(index)}
                />
              </Box>
            ))}

            {meteringPoints.length === 0 && (
              <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h6" color="error">
                  {t('steps.completion.error')}
                </Typography>
              </Box>
            )}
          </>
        )}

        {hasOnCompletionError.error && <ErrorMessage message={hasOnCompletionError.errorMessage} />}
      </Box>

      <Box>
        {!isHidden && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              size="lg"
              fullWidth
              onClick={goToCreateMeteringPoint}
              data-testid="create-metering-point-button"
              color="gradient"
            >
              {t('createMeteringPoint')}
            </Button>

            <Button
              size="lg"
              color="lightGrey"
              fullWidth
              onClick={onCompletionButtonPress}
              disabled={isDisabled}
              data-testid="generate-contract-button"
            >
              {t('continue')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
