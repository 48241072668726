'use client';

import { Box, FormHelperText, IconButton, Typography } from 'ui';
import { Delete, Edit } from 'ui/icons';

const Label = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body1" fontWeight="bold">
    {children}
  </Typography>
);

interface DataSectionProps {
  label: string;
  Value: React.ReactNode;
  onDelete?: () => void;
  onClick: () => void;
  deleteIcon?: boolean;
  error?: string;
  'data-testid'?: string;
}

export const DataSection = ({
  label,
  Value,
  onClick,
  onDelete,
  error,
  'data-testid': dataTestId,
  deleteIcon,
}: DataSectionProps) => (
  <Box>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={error && '1px solid red'}
      borderRadius={1}
    >
      <Box ml={1}>
        <Label>{label}</Label>
        {Value}
      </Box>

      <Box>
        {deleteIcon && (
          <IconButton onClick={onDelete} data-testid={`${dataTestId}-delete-button`}>
            <Delete sx={{ color: 'primary.dark' }} />
          </IconButton>
        )}

        <IconButton onClick={onClick} data-testid={`${dataTestId}-edit-button`}>
          <Edit sx={{ color: 'primary.dark' }} />
        </IconButton>
      </Box>
    </Box>
    {error && (
      <FormHelperText sx={{ ml: 1 }} error={Boolean(error)}>
        {error}
      </FormHelperText>
    )}
  </Box>
);
