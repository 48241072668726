'use client';

import { FormProvider, SubmitHandler, TextInput, useForm, yupResolver } from 'forms';
import Link from 'next/link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Form, FormHelperText } from 'ui';
import { object, string } from 'yup';

import { resetPassword } from '../../utils/resetPassword';

interface FormValues {
  email: string;
}

const initialFormValues = {
  email: '',
};

const PasswordResetForm = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const resolver = yupResolver(
    object({
      email: string().required(t('validation:required')).email(t('validation:invalidEmail')),
    })
  );

  const { handleSubmit, watch, ...rest } = useForm<FormValues>({
    defaultValues: initialFormValues,
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (formValues) => {
    setIsLoading(true);
    try {
      await resetPassword(formValues.email);
      setHelperText(t('auth:resetPasswordSuccess'));
    } catch (err) {
      setError(t('auth:resetPasswordError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...{ handleSubmit, watch, ...rest }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput required margin="normal" fullWidth label={t('email')} name="email" autoFocus />

        {(error || helperText) && <FormHelperText error={Boolean(error)}>{error || helperText}</FormHelperText>}

        <Box mb={2}>
          <Button type="submit" fullWidth color="gradient" loading={isLoading}>
            {t('common:continue')}
          </Button>
        </Box>
      </Form>
      <Link href="/login">{t('auth:login')}</Link>
    </FormProvider>
  );
};

export default PasswordResetForm;
