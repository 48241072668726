/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import { FormProvider, SubmitHandler, useForm, yupResolver } from 'forms';
import { useTranslation } from 'next-i18next';
import { Box, Form, Typography } from 'ui';
import { object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

import { NameInputs } from './NameInputs';

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
}

export const CustomerNameStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const { goToNextStep, getCustomerData, setName, navigateWithRedirect } = useCreateCustomer();

  const { firstName, middleName, lastName } = getCustomerData();

  const resolver = yupResolver(
    object({
      // @ts-expect-error - suppress the error for the translation function
      firstName: string().required(t('steps.name.fields.firstName.required')),
      // @ts-expect-error - suppress the error for the translation function
      lastName: string().required(t('steps.name.fields.lastName.required')),
      // @ts-expect-error - suppress the error for the translation function
      middleName: string().required(t('steps.name.fields.middleName.required')),
    })
  );

  const { handleSubmit, watch, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      firstName,
      lastName,
      middleName,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (name) => {
    trackTagManagerEvent(OnboardingEvents.customerName);
    setName(name);

    navigateWithRedirect(goToNextStep);
  };

  return (
    <FormProvider {...{ formState, handleSubmit, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.name.title')}</StepTitle>

            <Typography variant="subtitle2">{t('steps.name.description')}</Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <NameInputs translationNamespace="name" />
          </Box>
        </Box>

        <ContinueButton data-testid="customer-name-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
