import { useTranslation } from 'react-i18next';
import { CustomerCard } from 'ui';

import { useCustomer } from '~/customer';
import { getAddress } from '~/data';

export const Customer = ({ customerId }: { customerId: string }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useCustomer({ customerId });

  if (!data?.customer || isLoading) {
    return null;
  }

  const { name, address, representatives } = data.customer;

  return (
    <CustomerCard
      title={t('customers:id', { id: customerId })}
      subtitle={name}
      address={getAddress(address)}
      representativesLabel={t('customers:representatives')}
      representatives={representatives?.split(', ')}
    />
  );
};
