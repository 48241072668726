'use client';

import { useTranslation } from 'next-i18next';
import { Box, Button, Logo, Typography } from 'ui';
import { ArrowBackIosNewOutlined } from 'ui/icons';

import { Icon } from './Icon';

interface Props {
  title: string;
  subtitle: string;
  onBackClick?: () => void;
  showBackButton?: boolean;
  iconSource?: string;
}

export const ProcessDetails = ({ title, subtitle, onBackClick, showBackButton = true, iconSource }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" marginTop={5} height="80%">
        <Logo source="/images/logo-white.svg" />
        <Box marginTop={7} display="flex" flexDirection="column" flexGrow={1}>
          <Typography variant="h4" color="white" fontWeight="bold">
            {title}
          </Typography>
          <Typography marginTop={5} variant="subtitle1" color="white">
            {subtitle}
          </Typography>
          {iconSource && (
            <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
              <Icon source={iconSource} alt="account" />
            </Box>
          )}
        </Box>
      </Box>

      {showBackButton && (
        <Box display="flex" flexDirection="column" alignSelf="flex-start" marginBottom={10}>
          <Button
            color="lightGrey"
            size="lg"
            startIcon={<ArrowBackIosNewOutlined />}
            onClick={onBackClick}
            data-testid="back-button"
          >
            {t('common:back')}
          </Button>
        </Box>
      )}
    </>
  );
};
