import {
  Configuration,
  ContractApi,
  FileUploadV1Request,
  GetContractFileV1Request,
  GetContractFileV1200ResponseData,
} from '@toki-power/public-api';

import { Contract, GetAllOptions as GetAllOptionsType, Page } from '../types';

export type ContractFilter = {
  customerIdIn?: string;
};

export type ContractOrderBy = '-start_date';

interface GetAllOptions extends GetAllOptionsType<ContractFilter> {
  orderBy?: ContractOrderBy;
}

export type ContractReturnType = {
  uploadContract: (data: FileUploadV1Request) => Promise<void>;
  getAll: (options: GetAllOptions) => Promise<Page<Contract>>;
  getContractFile: (data: GetContractFileV1Request) => Promise<GetContractFileV1200ResponseData>;
};

export const contractApi = (config: () => Promise<Configuration>): ContractReturnType => {
  const api = async () => new ContractApi(await config());

  const getAll = async ({ page, filter, orderBy }: GetAllOptions = {}) =>
    (await api()).getAllContractsV1({
      customerIdIn: filter?.customerIdIn || undefined,
      orderBy: orderBy || undefined,
      page: page?.page,
      size: page?.size,
    });

  const uploadContract = async ({ contractNumber, uploadFile, fileType }: FileUploadV1Request) =>
    (await api()).fileUploadV1({ contractNumber, fileType, uploadFile }, ({ init }) => {
      return Promise.resolve({
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    });

  const getContractFile = async ({ contractNumber }: GetContractFileV1Request) =>
    (await (await api()).getContractFileV1({ contractNumber })).data;

  return {
    getAll,
    getContractFile,
    uploadContract,
  };
};
