import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'ui';

import { isRegistered } from '~/auth';

import { Section } from './Section';

interface Props {
  email: string;
}

export const Credentials = ({ email }: Props) => {
  const { t } = useTranslation('userImpersonation');
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  useEffect(() => {
    (async () => {
      if (email) {
        const isReg = await isRegistered(email);
        setIsUserRegistered(isReg);
      }
    })();
  }, [email]);

  return (
    <Section
      title={t('sections.authenticationCredentials.title')}
      description={t('sections.authenticationCredentials.description')}
    >
      <Box display="flex" flexDirection="row">
        {isUserRegistered ? (
          <Typography variant="body1" color="primary">
            {t('sections.authenticationCredentials.yes')}
          </Typography>
        ) : (
          <Typography variant="body1" color="error">
            {t('sections.authenticationCredentials.no')}
          </Typography>
        )}
      </Box>
    </Section>
  );
};
