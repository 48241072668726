'use client';

import { styled } from 'ui';

interface Props {
  source: string;
  alt: string;
}

const IconImage = styled('img')(({ theme }) => ({
  maxWidth: theme.spacing(20),
  width: '100%',
}));

export const Icon = ({ source, alt }: Props) => {
  return <IconImage src={source} alt={alt} />;
};
