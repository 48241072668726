import { BlenderEvents, trackTagManagerEvent } from 'firebase-client';
import { CardListContainer } from 'ui';

import { Customer } from '~/data';

import { CustomerCard } from '../detail';

export const CustomerCardList = ({ list }: { list: Customer[] }) => (
  <CardListContainer
    elements={list.map((customer) => (
      <CustomerCard
        key={customer.customerId}
        customer={customer}
        onClick={() => trackTagManagerEvent(BlenderEvents.customerDetails)}
      />
    ))}
  />
);
