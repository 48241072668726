'use client';

import { useFeatureIsOn } from 'feature-flag';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountBox, Assignment, EventNote, LocationOn, Person, Receipt } from 'ui/icons';

import { useAuthUser } from '~/auth';
import { useProfileType } from '~/data/hooks';
import { NavIcon } from '~/ui/layout';
import { doesEmailHaveImpersonationAccess } from '~/userImpersonation';

export const useNavigationOptions = () => {
  const isProducerEventsEnabled = useFeatureIsOn('producer_events');
  const { isAuthenticated, getUser } = useAuthUser();
  const { t } = useTranslation();

  const user = getUser();

  const { isProducer } = useProfileType();

  const navigationOptions = useMemo(() => {
    const options = isAuthenticated()
      ? [
          { href: '/invoices', icon: <NavIcon IconComponent={Receipt} />, label: t('nav.invoices') },
          { href: '/customers', icon: <NavIcon IconComponent={AccountBox} />, label: t('nav.customers') },
          { href: '/metering-points', icon: <NavIcon IconComponent={LocationOn} />, label: t('nav.meteringPoints') },
          { href: '/contracts', icon: <NavIcon IconComponent={Assignment} />, label: t('nav.contracts') },
          // { href: '/toki-gpt', icon: <TokiGPTNavIcon />, label: t('nav.tokiGPT') },
          ...(doesEmailHaveImpersonationAccess(user?.email || '')
            ? [
                {
                  href: '/user-impersonation',
                  icon: <NavIcon IconComponent={Person} />,
                  label: t('nav.userImpersonation'),
                },
              ]
            : []),
        ]
      : [
          { href: '/', icon: <NavIcon IconComponent={Receipt} />, label: t('nav.invoices') },
          { href: '/', icon: <NavIcon IconComponent={AccountBox} />, label: t('nav.customers') },
          { href: '/', icon: <NavIcon IconComponent={LocationOn} />, label: t('nav.meteringPoints') },
          { href: '/', icon: <NavIcon IconComponent={Assignment} />, label: t('nav.contracts') },
          // { href: '/toki-gpt', icon: <TokiGPTNavIcon />, label: t('nav.tokiGPT') },
        ];

    if (isProducerEventsEnabled && isProducer()) {
      options.push({
        href: '/producer-events',
        icon: <NavIcon IconComponent={EventNote} />,
        label: t('nav.producerEvents'),
      });
    }

    return options;
  }, [isAuthenticated, isProducerEventsEnabled, t, isProducer, user?.email]);

  return navigationOptions;
};
