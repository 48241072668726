'use client';

import { useTranslation } from 'react-i18next';
import { Box, ErrorFallback } from 'ui';

export const FallbackComponent = () => {
  const { t } = useTranslation('common');
  const errorTitle = t('errorPage.default.title');
  const errorDescription = t('errorPage.default.description');
  const supportEmail = t('supportEmail');

  return (
    <Box height="100vh">
      <ErrorFallback title={errorTitle} description={errorDescription} supportEmail={supportEmail} />
    </Box>
  );
};
