import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { i18nConfig } from './config';

const initI18nextServerSide = async () => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)
      )
    )
    .init(i18nConfig);
  return i18nInstance;
};

export const getTranslation = async (namespace: string, language = 'bg') => {
  const i18nextInstance = await initI18nextServerSide();
  const t = i18nextInstance.getFixedT(language, namespace);

  return {
    i18n: i18nextInstance,
    t,
  };
};
