import { formatDate, toMonthNameAndYearString } from 'dates';
import { Trans, useTranslation } from 'next-i18next';
import { memo } from 'react';
import { InvoiceCard as UiInvoiceCard, Typography } from 'ui';

import { Invoice, PointInvoiceAddendum } from '~/data';
import { isInvoice } from '~/data/types';
import { useInvoiceBalance } from '~/invoice/hooks/useInvoiceBalance/useInvoiceBalance';
import { LinkContainer } from '~/ui';

import getInvoicePath from '../../../helpers/getInvoicePath';
import { recordInvoiceDownload } from '../../../helpers/getInvoicePath/recordInvoiceDownload';

export const InvoiceCard = memo(
  ({ invoice, onClick }: { invoice: Invoice | PointInvoiceAddendum; onClick?: () => void }) => {
    const { t } = useTranslation();
    const {
      invoiceNumber,
      invoiceDate,
      paymentDeadline,
      meteringPointsCount,
      totalInvoiceCost,
      customer,
      status,
      balance,
    } = invoice;

    const downloadUrl = isInvoice(invoice) && invoice.pdfUrl !== null ? invoice.pdfUrl : undefined;

    const paymentBalance = useInvoiceBalance({ balance });

    return (
      <LinkContainer href={getInvoicePath(invoice)} onClick={onClick}>
        <UiInvoiceCard
          subtitle={t('invoices:invoiceNumber', {
            invoiceNumber,
          })}
          title={toMonthNameAndYearString({
            date: invoiceDate,
          })}
          data-testid="invoice-card"
          downloadUrl={downloadUrl}
          amount={totalInvoiceCost}
          details={[
            <Trans
              i18nKey="invoices:date"
              values={{
                date: formatDate({
                  date: invoiceDate,
                }),
              }}
              components={[<Typography component="span" variant="inherit" display="inline" />]}
            />,
            <Trans
              i18nKey="invoices:dueDate"
              values={{
                dueDate: formatDate({
                  date: paymentDeadline,
                }),
              }}
              components={[<Typography component="span" variant="inherit" display="inline" />]}
            />,
            t('invoices:meteringPoints', {
              meteringPointsCount,
            }),
          ]}
          paymentBalance={paymentBalance}
          // @ts-expect-error - suppress the error for the translation function
          statusText={t(`invoices:${status === 'Part-paid' ? 'partPaid' : status.toLowerCase()}`)}
          customerName={customer.name}
          onInvoiceDownload={() => isInvoice(invoice) && recordInvoiceDownload(invoice)}
          status={status}
        />
      </LinkContainer>
    );
  }
);
