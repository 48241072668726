import { FirebaseError } from '@firebase/util';

import { AuthWrapper } from './components/AuthWrapper';
import { LoginForm } from './components/LoginForm';
import { PasswordResetForm } from './components/PasswordResetForm';
import { AuthTranslation } from './components/shared';
import { SignUpForm } from './components/SignupForm';
import { AuthUser, AuthUserProvider, ImpersonatedUser, useAuthUser } from './hooks/useAuthUser';
import { createUser } from './utils/createUser';
import { isRegistered } from './utils/isRegistered';
import { loginUser } from './utils/loginUser';
import { logoutUser } from './utils/logoutUser';
import { withAuthPage } from './utils/withAuthPage';

export type { AuthUser, ImpersonatedUser };

export {
  AuthTranslation,
  AuthUserProvider,
  AuthWrapper,
  createUser,
  FirebaseError,
  isRegistered,
  LoginForm,
  loginUser as loginUserWithPassword,
  logoutUser,
  PasswordResetForm,
  SignUpForm,
  useAuthUser,
  withAuthPage,
};
