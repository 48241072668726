import { useTranslation } from 'react-i18next';
import { Box, Button, useResolution } from 'ui';

import { useAuthUser } from '~/auth';

import { useImpersonation } from '../hooks/useImpersonation';
import { Section } from './Section';

interface Props {
  email: string;
  customerIds: string[];
}

export const Actions = ({ email, customerIds }: Props) => {
  const { isTablet } = useResolution();
  const { t } = useTranslation('userImpersonation');
  const { startImpersonation, stopImpersonation } = useImpersonation({ customerIds, email });
  const { getImpersonatedUser } = useAuthUser();

  const impersonatedEmail = getImpersonatedUser()?.email || '';

  return (
    <Section title={t('sections.actions.title')} description={t('sections.actions.description')}>
      <Box display="flex" gap={2} flexWrap="wrap">
        <Button
          color="primary"
          onClick={startImpersonation}
          disabled={email === impersonatedEmail}
          size="md"
          fullWidth={!isTablet}
          data-testid="switch-to-button"
        >
          {t('sections.actions.buttons.switchTo.label', { email })}
        </Button>

        <Button
          color="primary"
          onClick={stopImpersonation}
          disabled={!impersonatedEmail}
          size="md"
          fullWidth={!isTablet}
          data-testid="switch-back-button"
        >
          {t('sections.actions.buttons.switchBack.label')}
        </Button>
      </Box>
    </Section>
  );
};
