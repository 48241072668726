'use client';

import logger from 'logger';
import { useTranslation } from 'next-i18next';
import { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { addError, Errors } from 'rum';
import { Box, ErrorFallback } from 'ui';

import { useNavigationOptions } from '~/data';
import { ResponseError } from '~/data/api';

import { AppBar } from '../AppBar';
import BottomNavigation from '../BottomNavigation/BottomNavigation';
import Sidebar from '../Sidebar/Sidebar';

interface FallbackComponentProps {
  error: Error | ResponseError | null;
}

const onError = (error: Error, info: ErrorInfo) => {
  logger.error('error :', error);
  addError(Errors.UNHANDLED_NAVIGATION_LAYOUT_ERROR, error, {
    info,
  });
};

const FallbackComponent = ({ error }: FallbackComponentProps) => {
  const { t } = useTranslation('common');

  const supportEmail = t('supportEmail');

  if (error instanceof ResponseError && error.response.status === 404) {
    return (
      <ErrorFallback
        showLogo={false}
        title={t('errorPage.notFound.title')}
        description={t('errorPage.notFound.description')}
        supportEmail={supportEmail}
      />
    );
  }

  return (
    <ErrorFallback
      showLogo={false}
      title={t('errorPage.default.title')}
      description={t('errorPage.default.description')}
      supportEmail={supportEmail}
    />
  );
};

interface UnauthNavigationLayoutProps {
  children: React.ReactNode;
  background?: string;
}

const UnauthNavigationLayout = ({ children, background }: UnauthNavigationLayoutProps) => {
  const navigationOptions = useNavigationOptions();

  return (
    <Box
      display="flex"
      flex={1}
      sx={(theme) => ({
        backgroundColor: theme.backgrounds.page,
        height: theme.dimensions.pageHeight,
      })}
    >
      <Sidebar options={navigationOptions} />
      <BottomNavigation options={navigationOptions} />

      <Box
        sx={({ dimensions }) => ({
          background,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: { lg: '100%', xs: `calc(100% - ${dimensions.bottomNavigationHeight})` },
          overflow: 'auto',
          width: '100%',
        })}
      >
        <AppBar />

        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          data-testid="navigation-layout-content"
          px={{ lg: 6, xs: 2 }}
          pt={2.5}
        >
          <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
            {children}
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default UnauthNavigationLayout;
