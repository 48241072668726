import { Box, Grid, Paper, Typography } from 'ui';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const AuthPage = ({ children, title }: Props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      p={2}
      sx={(theme) => ({
        backgroundColor: theme.backgrounds.page,
        height: theme.dimensions.pageHeight,
      })}
    >
      <Paper
        sx={{
          width: 420,
        }}
      >
        <Typography component="h1" variant="h4" align="center">
          {title}
        </Typography>
        <Box sx={{ mt: 4 }}>{children}</Box>
      </Paper>
    </Grid>
  );
};
