'use client';

import React, { ErrorInfo } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { addError, Errors } from 'rum';

import { FallbackComponent } from '~/ui';

const onError = (error: Error, info: ErrorInfo) => {
  addError(Errors.UNHANDLED_ERROR, error, {
    info,
  });
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
